<template>
  <router-link
    class="aside-link"
    :class="{ 'with-status': !!status }"
    :to="to">
    <SideBarStatus
      v-if="status"
      :status="status"
      class="aside-link__status" />
    <span class="aside-link__text">{{ title }}</span
    ><span
      v-if="isSpecial"
      class="aside-link__tag"
      >спецпроект</span
    >
    <slot></slot>
  </router-link>
</template>

<script>
import SideBarStatus from "./SideBarStatus";

export default {
  name: "SideBarLink",
  components: { SideBarStatus },
  props: {
    to: {
      type: [String, Object],
    },
    title: {
      type: String,
    },
    isSpecial: {
      type: Boolean,
    },
    status: {
      type: String,
    },
  },
};
</script>

<style lang="less">
.aside-link {
  text-decoration: none;
  color: #fff;
  .fz(14);
  margin-bottom: 0.5em;
  position: relative;
  display: block;

  &.with-status {
    padding-left: 1.4em;
  }

  &__text {
    opacity: 0.6;
    transition: opacity 0.4s;
    display: inline-block;
  }

  &__tag {
    font-size: 10px;
    line-height: 12px;
    display: inline-block;
    color: lighten(@link-color, 20%);
    background-color: fade(@link-color, 20%);
    padding: 2px 6px;
    border-radius: @radius-m;
    margin-left: 0.4em;
  }

  &:hover &__text,
  &.router-link-active &__text {
    opacity: 1;
  }

  &__status {
    width: 1em;
    height: 1em;
    position: absolute;
    left: 0;
    top: 1px;
  }
}
</style>
