<template>
  <nav class="top-nav">
    <div
      v-for="item in routes"
      :key="item.name"
      class="top-nav__item">
      <router-link
        class="top-nav__link"
        :to="{ name: item.name }">
        <BaseIcon
          v-if="item.icon"
          :glyph="item.icon"
          width="18px"
          class="top-nav__item-icon" />
        {{ item.title }}
      </router-link>
    </div>
    <div class="top-nav__item top-nav__item--notice">
      <a
        href="#"
        class="top-nav__link"
        @click.prevent="handleNoticeClick">
        <BaseIcon
          glyph="bell-o"
          class="top-nav__item-icon notice-icon"
          width="18px" />
        <span class="notice-title">Уведомления</span>
        <span
          v-if="msgCount"
          class="top-nav__tag"
          >{{ msgCount }}</span
        >
      </a>
    </div>
    <BviButton class="special-button" />
    <div class="top-nav__item top-nav__help">
      <router-link
        :to="{ name: 'support' }"
        class="top-nav__link">
        ?
        <span>Помощь</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { checkNotificationPromise } from "@/utils/notifications";
import { notificationsSupported } from "@/services/notifications";
import BviButton from "@/components/BviButton.vue";

export default {
  name: "TopNavigation",
  components: { BviButton },
  props: {
    routes: {
      type: Array,
    },
    msgCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isMentor() {
      return this.$store.getters["user/isMentor"];
    },
  },
  methods: {
    handleNoticeClick() {
      const { isMentor } = this;
      if (notificationsSupported) {
        checkNotificationPromise();
      }

      if (
        ["mentor-notifications", "user-notifications"].includes(
          this.$route.name
        )
      ) {
        return;
      }
      this.$router.push({
        name: isMentor ? "mentor-notifications" : "user-notifications",
      });
    },
  },
};
</script>

<style lang="less">
.top-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px 0;
  margin-left: -5px;
  margin-right: 0;

  &__tag {
    display: inline-block;
    font-size: 10px;
    background-color: @error-color;
    position: absolute;
    top: 0.2em;
    height: 1.4em;
    width: 1.4em;
    line-height: 1.4;
    font-weight: bold;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    right: 1.4em;
  }

  &__item {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 5px;
    margin-right: 5px;

    &-icon {
      margin-right: 8px;
      vertical-align: sub !important;

      &.notice-icon {
        margin-right: 0;
      }
    }
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid @link-blue;
    color: @link-blue;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: @btn-radius;
    transition: color 0.3s, background-color 0.3s;
    position: relative;

    &:hover {
      background-color: @link-blue;
      color: #fff;
    }
  }

  &__help {
    margin-right: 0;
  }
  &__help &__link {
    font-size: 22px;
    font-weight: 600;
    padding: 0 11px;

    span {
      display: none;
    }
  }

  @media (max-width: @screen-md) {
    margin: 0;

    &__item {
      margin: 0;
      flex: 1 0 auto;

      &--notice {
        display: none !important;
      }

      &-icon {
        margin-right: 0;
        margin-bottom: 3px;
      }
    }

    &__link {
      border: none;
      height: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-transform: initial;
      font-size: 12px;
      line-height: 12px;
      padding-left: 7px;
      padding-right: 7px;

      &:hover {
        background-color: #fff;
        color: @link-blue;
      }
    }

    &__help {
      margin-left: auto;
    }

    &__help &__link {
      line-height: 1;
      span {
        display: block;
        font-size: 12px;
        font-weight: initial;
      }
    }
  }

  .notice-title {
    display: none;

    .media-max-sm({
      display: block;
    });
  }
}

.special-button {
  align-self: center;
  margin-left: auto;
  margin-right: 10px;

  @media screen and (max-width: 1400px) {
    & .bvi-text {
      display: none;
    }
  }

  @media screen and (max-width: @screen-md) {
    display: none !important;
  }
}
</style>
