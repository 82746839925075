<template>
  <div class="page">
    <aside
      class="page__aside aside"
      :class="{ 'is-active': showSidebar }">
      <div
        v-if="user"
        class="aside__header">
        <UserBlock
          :user="user"
          :track="trackTitle"
          :is-mentor="isMentor" />
        <div class="aside__close">
          <BaseIcon
            glyph="close"
            width="20px" />
        </div>
      </div>

      <div class="aside__body scroll-content">
        <slot name="aside"></slot>
      </div>
    </aside>
    <div
      class="page__overlay"
      :class="{ 'is-active': showSidebar }" />
    <header class="header">
      <div class="header__inner">
        <div
          v-if="user"
          class="header-menu"
          role="button"
          aria-label="Показать меню"
          @click="toggleSidebar">
          <span class="dots"></span>
          <div class="header__user">
            <BaseAvatar
              :gender="user.sex"
              :image="user.avatar" />
          </div>
        </div>

        <a
          href="/"
          class="header__logo"
          ><img
            src="@/assets/images/logo_horizontal.svg"
            alt=""
        /></a>

        <BviButton />

        <div
          class="header__notice"
          @click="handleNotificationClick">
          <BaseIcon glyph="bell" />
          <span
            v-if="msgCount"
            class="header__notice-tag"
            >{{ msgCount }}</span
          >
        </div>
      </div>
    </header>
    <div
      id="main-content"
      class="page__body">
      <div class="page__container">
        <header class="page__header">
          <TopNavigation
            :msg-count="msgCount"
            :routes="routes" />
        </header>
        <slot></slot>
      </div>

      <AppFooter
        theme="light"
        class="page__footer"
        insert>
        <template #links>
          <a
            href="https://ntcontest.ru/tracks"
            target="_blank"
            rel="noopener noreferrer"
            >Подробнее о профилях</a
          >
          <a
            href="https://ntcontest.ru/study/materials"
            target="_blank"
            rel="noopener noreferrer"
            >Материалы для подготовки</a
          >
          <a
            href="https://vk.com/nticontest"
            target="_blank"
            rel="noopener noreferrer"
            >Задайте вопрос ВКонтакте</a
          >
        </template>
      </AppFooter>
    </div>
    <div class="page__menu">
      <TopNavigation
        :msg-count="msgCount"
        :routes="routes" />
    </div>
    <slot name="modals"></slot>
    <!-- Контейнер для динамических модалок -->
    <ModalsContainer />
    <notifications group="base" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopNavigation from "@/components/TopNavigation";
import UserBlock from "@/components/sidebar/UserBlock";
import AppFooter from "./AppFooter";
import { checkNotificationPromise } from "@/utils/notifications";
import { clientApiRequest, talentRequest } from "@/services/api";
import ReleaseModal from "@/modals/ReleaseModal";
import { CHECK_RELEASE_INTERVAL, TOKEN_COOKIE_KEY } from "@/constants";
import { notificationsSupported } from "@/services/notifications";
import { SocketService } from "@/services/socketService";
import { getCookie } from "@/utils/cookies";
import BviButton from "@/components/BviButton.vue";
export default {
  name: "CabinetLayout",
  components: { BviButton, TopNavigation, UserBlock, AppFooter },
  data() {
    return {
      showSidebar: false,
      releaseInterval: null,
      isModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      routes: "ui/baseNav",
      user: "user/user",
      isMentor: "user/isMentor",
      isStudent: "participant/isStudent",
      isJunior: "participant/isJunior",
      msgCount: "notifications/msgCount",
    }),

    release() {
      return this.$store.state.release;
    },
    track() {
      return this.$store.state.participant.track;
    },
    trackTitle() {
      const { track, isMentor, isJunior, isStudent } = this;
      if (isJunior) return track?.title;
      if (isStudent) {
        return "Студенческий трек";
      }
      if (!isMentor && !isJunior) {
        return "Школьники 8-11 классы";
      }
      return isMentor ? "Наставник" : "";
    },
  },
  mounted() {
    setTimeout(() => {
      this.checkRelease();
    }, 3000);
    this.releaseInterval = setInterval(
      this.checkRelease,
      CHECK_RELEASE_INTERVAL
    );

    const token = getCookie(TOKEN_COOKIE_KEY);
    if (token) {
      SocketService.init(token, this.$store, talentRequest);
    }
  },
  beforeDestroy() {
    SocketService.destroy();
    clearInterval(this.releaseInterval);
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      if (this.showSidebar) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleCloseClick, true);
          document.body.classList.add("no-scroll");
        });
      }
    },
    handleCloseClick(e) {
      if (this.showSidebar && !e.target.closest(".header__user")) {
        this.showSidebar = false;
        document.removeEventListener("click", this.handleCloseClick);
        document.body.classList.remove("no-scroll");
      }
    },
    handleNotificationClick() {
      const { isMentor } = this;
      if (notificationsSupported) {
        checkNotificationPromise();
      }
      if (
        ["mentor-notifications", "user-notifications"].includes(
          this.$route.name
        )
      ) {
        return;
      }
      if (isMentor) {
        this.$router.push({ name: "mentor-notifications" });
      } else {
        this.$router.push({ name: "user-notifications" });
      }
    },
    async checkRelease() {
      if (this.isModalActive) return;
      try {
        const { data } = await clientApiRequest({
          method: "GET",
          url: "/build_version",
        });
        if (data.version !== this.release) {
          this.isModalActive = true;
          this.$modal.show(
            ReleaseModal,
            {},
            {
              adaptive: true,
              height: "auto",
              scrollable: true,
              minHeight: 200,
            },
            {
              closed: () => {
                this.isModalActive = false;
              },
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="less" scoped="true">
@dot-size: 4px;

.header-menu {
  height: 32px;
  position: relative;
  padding-left: 10px;
  flex-grow: 0;

  .dots {
    height: @dot-size;
    width: @dot-size;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -4px;
    transform: translateY(-50%);

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }

    &::before {
      top: @dot-size* (-2);
    }

    &::after {
      top: @dot-size*2;
    }
  }
}
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;

  &__aside {
    background-color: @aside-bg-color;
    color: #fff;
    flex: 0 0 320px;
    min-width: 320px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding-top: @aside-inner-offset;
    min-width: 0;
  }

  &__container {
    width: 100%;
    max-width: 86%;
    padding-left: 20px;
    padding-right: 20px;
    flex-grow: 1;

    // prettier-ignore
    .media-only-xs ({
      padding-left: 10px;
      padding-right: 10px;
    });
  }

  &__footer {
    width: 100%;
  }

  &__menu {
    display: none;
  }

  @media (max-width: @screen-lg) {
    &__container {
      max-width: 100%;
    }
  }

  @media (max-width: @screen-md) {
    flex-direction: column;

    &__overlay {
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 12;
      display: none;

      &.is-active {
        display: block;
      }
    }

    &__header {
      display: none;
    }

    &__menu {
      display: block;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 10;
    }
  }
}

.aside {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    padding: @aside-inner-offset;
    padding-top: @aside-inner-offset - 5px;
  }

  &__body {
    flex-grow: 1;
    max-height: initial;
  }

  &__close {
    display: none;
  }

  @media (max-width: @screen-md) {
    width: 320px;
    position: fixed;
    transform: translateX(-100%);
    transition: transform 0.3s;
    z-index: 13;

    &.is-active {
      transform: translateX(0);
    }

    &__header {
      display: flex;
      align-items: center;
    }

    .user-block {
      flex-grow: 1;
      padding-right: 10px;
    }

    &__close {
      display: block;
      width: 32px;
      text-align: center;
      line-height: 32px;
    }
  }
}

.header {
  background-color: @black-menu;
  color: #fff;
  padding: 12px 0;
  display: none;
  position: sticky;
  top: 0;
  z-index: 10;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__user {
    width: 34px;
    color: @black-menu;
  }

  &__logo {
    height: 30px;
    flex-grow: 1;
    text-align: center;

    img {
      height: 100%;
    }
  }

  &__notice {
    width: 34px;
    font-size: 20px;
    text-align: right;
    padding-right: 4px;
    padding-left: 4px;
    text-align: center;
    position: relative;
    cursor: pointer;

    &-tag {
      display: inline-block;
      font-size: 10px;
      background-color: @error-color;
      position: absolute;
      top: 0.1em;
      height: 1.4em;
      width: 1.4em;
      line-height: 1.4;
      font-weight: bold;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      right: 0.1em;
    }
  }

  @media (max-width: @screen-md) {
    display: block;
  }
}
</style>
