<template>
  <span
    class="aside-status"
    :class="status">
    <BaseIcon
      valign="baseline"
      :glyph="glyph" />
  </span>
</template>

<script>
export default {
  name: "SideBarStatus",
  props: {
    status: {
      type: String,
      default: "pending",
      validator(value) {
        return ["warn", "ok", "pending"].includes(value);
      },
    },
  },
  computed: {
    glyph() {
      switch (this.status) {
        case "warn":
          return "warn";
        case "ok":
          return "done";
        default:
          return "waiting";
      }
    },
  },
};
</script>

<style lang="less">
.aside-status {
  display: inline-block;
  vertical-align: middle;

  &.warn {
    color: @error-color;
  }

  &.ok {
    color: @success-color;
  }

  &.pending {
    color: lighten(@link-color, 20%);
  }
}
</style>
