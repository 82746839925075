import { Centrifuge } from "centrifuge";
const connectionEndpoint = `${process.env.VUE_APP_CENTRIFUGE_ORIGIN}/connection/websocket`;
export class SocketService {
  /**
   * Подписываемся на канал, с помощью серверной подписки
   * @param {import("axios").AxiosInstance} transport
   * @returns {Centrifuge}
   */
  static async subscribe(transport) {
    if (this._isSubscribed) return;
    try {
      await transport.request({
        method: "POST",
        url: `/notifications/ws/subscribe`,
        data: {
          client: this._instance?._client,
        },
      });
      this._isSubscribed = true;
    } catch (error) {
      console.log("subscribe error", error);
      throw error;
    }
  }
  /**
   *
   * @param {string} token
   * @returns {Centrifuge}
   */
  static connect(token) {
    if (process.server) {
      throw new Error("Centrifuge доступен только на стороне клиента!");
    }
    if (!token) {
      throw new Error("Centrifuge error. Отсутствует параметр token.");
    }
    // если запросили коннект с новым токеном
    if (this._token && this._token !== token) {
      try {
        this._instance?.disconnect();
        this._instance = null;
        this._isSubscribed = false;
      } catch (error) {
        // do nothing
      }
    }
    this._token = token;
    if (!this._instance) {
      // если инстанса нет
      this._instance = new Centrifuge(connectionEndpoint, {
        token: this._token,
      });
    }
    this._instance.connect();
    return this._instance;
  }
  static destroy() {
    if (this._instance) {
      this._instance.disconnect();
      this._instance = null;
    }
    this._token = "";
    this._isStoreConnected = false;
    this._isSubscribed = false;
  }
  /**
   *
   * @param {import("vuex").Store} store
   */
  static connectStore(store) {
    if (this._isStoreConnected) return;
    this._instance.on("publication", (ctx) => {
      store.dispatch("notifications/receiveMessage", ctx.data);
    });
    this._instance.on("error", (error) => {
      console.log("error", error);
    });
    this._isStoreConnected = true;
  }
  /**
   *
   * @param {string} token
   * @param {import("vuex").Store} store
   * @param {import("axios").AxiosInstance} transport
   * @returns {Centrifuge}
   */
  static init(token, store, transport) {
    const inst = this.connect(token);
    this.connectStore(store);
    inst.on("connected", () => {
      this.subscribe(transport);
    });
    return inst;
  }
}
