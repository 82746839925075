<template>
  <div class="user-block">
    <div class="user-block__icon">
      <BaseAvatar
        class="aside__image"
        :gender="user.sex"
        :image="user.avatar"
        @click.native="
          $router.push({ name: isMentor ? 'mentor-account' : 'user-account' })
        " />
    </div>
    <div class="user-block__data">
      <div class="user-block__links">
        <router-link
          :to="{ name: isMentor ? 'mentor-account' : 'user-account' }"
          class="user-block__link"
          title="Перейти к учетной записи">
          <span class="text-ellipsis user-block__link-text"
            >{{ user.first_name }} {{ (user.last_name || "")[0] }}.</span
          >
          <span class="user-block__link-icon"
            ><svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="5.96643"
                cy="3"
                r="2.09938"
                stroke-width="0.801245" />
              <path
                d="M4.96643 6.90062H6.96643C8.95431 6.90062 10.5658 8.51212 10.5658 10.5V11.0994H1.36705V10.5C1.36705 8.51212 2.97855 6.90062 4.96643 6.90062Z"
                stroke-width="0.801245" />
            </svg>
          </span>
        </router-link>
        <a
          :href="`${$store.state.clientApiPrefix}/logout/?next=/`"
          class="user-block__link"
          title="Выйти из учетной записи">
          <span class="user-block__link-text">Выйти</span>
          <span class="user-block__link-icon"
            ><svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.56892 0.937744H8.01637C8.9014 0.937744 9.61886 1.6552 9.61886 2.54023V11.421C9.61886 12.306 8.9014 13.0234 8.01637 13.0234H2.56892C1.68389 13.0234 0.966431 12.306 0.966431 11.421V7.58077H5.06508L3.54541 9.10044L4.39394 9.94897L6.93787 7.40503L7.36214 6.98077L6.93787 6.5565L4.39394 4.01256L3.54541 4.86109L5.06508 6.38077H0.966431V2.54024C0.966431 1.6552 1.68389 0.937744 2.56892 0.937744Z" /></svg
          ></span>
        </a>
      </div>
      <div class="user-block__meta">
        <div
          v-if="track"
          class="user-block__meta-track">
          {{ track }}
        </div>
        <div
          class="user-block__meta-id"
          title="Скопировать Talent ID"
          @click="handleCopyId">
          ID #{{ user.talent_id }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/utils";

export default {
  name: "UserBlock",
  props: {
    track: {
      type: String,
    },
    user: {
      type: Object,
      required: true,
    },
    isMentor: {
      type: Boolean,
    },
  },
  methods: {
    handleCopyId() {
      copyToClipboard(this.user.talent_id);
    },
  },
};
</script>

<style lang="less" scoped>
.user-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .fz(14);

  &__icon {
    width: 38px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 13px;
    margin-top: 6px;
    color: @bg-dark;
    cursor: pointer;
  }

  &__data {
    flex-grow: 1;
  }

  &__link {
    display: inline-flex;
    padding: 4px 0;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
    align-items: center;
    margin-right: 10px;

    &:hover,
    &:focus {
      color: @link-color;
      outline: none;
    }

    &-icon {
      fill: currentColor;
      margin-left: 4px;
      margin-top: 3px;
    }
  }

  &__meta {
    display: flex;
    flex-flow: row wrap;
    .fz(12);

    &-track {
      margin-right: 10px;
      opacity: 0.5;
      border-bottom: 1px dashed transparent;
    }

    &-id {
      opacity: 0.5;
      transition: color 0.3s, opacity 0.3s;
      cursor: pointer;
      border-bottom: 1px dashed transparent;

      &:hover {
        color: @link-color;
        opacity: 1;
        border-bottom-color: fade(@link-color, 50%);
      }
    }
  }
}
</style>
